<template lang="pug">
  b-row.mb-5
    b-col
      accounts-form(:action='action')
</template>

<script>
import AccountsForm from '@views/container/manager/accounts/Form'

export default {
  name: 'manager-accounts-new',
  components: {
    AccountsForm,
  },
  props: {
    action: String,
  },
}
</script>
